<template>
  <div>
    <div class="d-md-flex w-100 mb-4 justify-content-betwe">
      <div class="d-flex align-items-end justify-content-between w-100">
        <div class="input-search mb-2 col-md-7 p-0">
          <b-form-input
            autocomplete="off"
            @input="handleSearch"
            v-model="search"
            placeholder="Digite para pesquisar"
            class="col-3 mb-2"
          />
          <b-button
            variant="light"
            class="px-2 d-flex align-items-center absolute icon-action"
          >
            <b-icon icon="search"></b-icon>
          </b-button>
        </div>
      </div>

      <b-form-group
        :label="getLabelPercentage()"
        class="p-0 w-100 d-block col-md-3 mb-0"
      >
        <b-form-input
          v-model="percentage"
          type="range"
          step="25"
          min="0"
          class="w-100"
          @change="handleChangePercentage"
        />
      </b-form-group>
    </div>
    <skeleton height="198px" rows="8" grid="4" gap="8" v-if="isBusy" />
    <empty-list v-if="items && items.length === 0 && !isBusy" />
    <cards-list grid="4" class="mt-4" gap="10" v-else>
      <b-card
        v-for="(item, index) in items"
        :key="index"
        class="card-hoverable pointer"
      >
        <router-link :to="`/borrower/edit/${item.id}`" class="text-muted">
          <section class="d-md-block">
            <div class="d-flex align-items-center justify-content-start gap-2">
              <avatar :name="item.social_reason" :image="item.photo" />
              <strong class="d-block" v-if="item.social_reason">
                {{ item.social_reason }}
              </strong>
              <i class="d-block" v-else> Cadastro incompleto </i>
            </div>
            <section>
              <small class="my-3 d-block">
                <strong>Nome fantasia:</strong> <br />
                {{ item.fantasy_name || "---" }}
              </small>
              <small class="mb-3">
                <strong>E-mail:</strong> <br />
                {{ item.email || "Não preenchido" }}
              </small>
              <small v-if="item && item.enterprise" class="d-block">
                <strong>Empresa:</strong> {{ item.enterprise.social_reason }}
              </small>
            </section>
          </section>
        </router-link>
      </b-card>
    </cards-list>

    <b-pagination
      @change="handleChangePage"
      v-model="current_page"
      :total-rows="total"
      :per-page="per_page"
      aria-controls="my-table"
    />
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  mixins: [listPaginated],

  data() {
    return {
      action_name: "borrower",
      params: {},
      selectedUser: {},
      borrower: {},
      selectedId: null,
      isAdd: false,
      progress: 100,
      percentage: 100,
    };
  },

  methods: {
    ...mapActions(["get_all_profile"]),

    handleChangePercentage: _.debounce(function(value) {
      this.progress = value ?? 0;
      this.params = { ...this.params, progress: this.progress };
      this.getList();
    }, 500),

    getLabelPercentage() {
      const progress = +this.progress;
      if (progress === 0 || !progress) {
        return "Não iniciaram o cadastro";
      }

      if (progress === 100) {
        return `Preencheram ${progress}% do perfil`;
      }
      return `Preencheram até ${progress}% do perfil`;
    },
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile"]),
  },
  created() {
    this.get_all_profile().then(({ results }) => {
      this.menus = results || [];
      this.menus = [{ name: "Todos", id: -1, editable: false }, ...this.menus];
      this.selectedUser = this.menus[0];
    });
  },
};
</script>
