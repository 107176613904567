<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="input-search mb-4">
        <b-form-input
          @input="handleSearch"
          v-model="search"
          placeholder="Digite para pesquisar"
          class="col-3 mb-2"
        />
        <b-button
          variant="light"
          class="px-2 d-flex align-items-center absolute icon-action"
        >
          <b-icon icon="search"></b-icon>
        </b-button>
      </div>
      <b-button
        variant="secondary"
        @click="handleInviteBorrower()"
        class="btn-add"
      >
        Convidar
      </b-button>
    </div>

    <div class="my-2 mb-4" v-if="!isBusy">
      <b-button
        small
        size="sm"
        :variant="`${status === 1 ? 'primary' : 'light'}`"
        @click="handleSetStatus(1)"
        class="mr-1"
      >
        Enviados
      </b-button>
      <b-button
        small
        size="sm"
        :variant="`${status === 2 ? 'primary' : 'light'}`"
        @click="handleSetStatus(2)"
        class="mr-1"
      >
        Aceitos
      </b-button>
    </div>

    <skeleton height="198px" rows="8" grid="4" gap="8" v-if="isBusy" />
    <empty-list v-if="items && items.length === 0 && !isBusy" />
    <div v-else>
      <cards-list grid="4" gap="8">
        <b-card v-for="(item, index) in items" :key="index" class="py-1">
          <section class="d-flex w-100">
            <section class="w-100">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="status === 1"
              >
                <strong
                  class="d-block col-8 p-0"
                  :class="item.is_expired ? 'text-danger' : 'text-success'"
                >
                  {{ item.is_expired ? "Convite expirado" : "Convite válido" }}
                </strong>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-else
              >
                <strong class="d-block col-8 p-0 text-primary">
                  Convite aceito
                </strong>
              </div>
              <small class="mb-3 d-block">
                <strong>E-mail: </strong> <br />
                {{ item.email }}
              </small>
              <small class="d-block">
                <strong>Convidado em: </strong> <br />
                {{ moment(item.updated_at).format("DD/MM/YYYY HH:mm") }}
              </small>
            </section>
          </section>
          <div class="actions" v-if="status === 1">
            <a
              v-disable="resendId === item.id"
              title="Reenviar"
              class="
                text-center
                pointer
                d-flex
                align-items-center
                line-height-1
                mt-3
                btn-outline-primary
                btn-sm
                d-table
                btn
              "
              @click="$emit('resent_invite', { item: { ...item } })"
            >
              <b-spinner
                class="h6 mr-1 mb-0"
                v-if="resendId === item.id"
                small
                type="grow"
              />
              <span>Reenviar</span>
            </a>
          </div>
        </b-card>
      </cards-list>

      <b-pagination
        @change="handleChangePage"
        v-model="current_page"
        :total-rows="total"
        :per-page="per_page"
        aria-controls="my-table"
      />
    </div>
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapGetters } from "vuex";

export default {
  mixins: [listPaginated],

  props: {
    resendId: {
      type: String,
    },
    params: {
      type: Object,
    },
  },
  data() {
    return {
      action_name: "notifications",
      borrower: null,
      selectedId: null,
      isEmpty: false,
      statusInvite: [
        { id: 1, name: "Pendente" },
        { id: 2, name: "Aceito" },
        { id: 3, name: "Recusado" },
        { id: 4, name: "Cancelado" },
      ],
      status: 1,
    };
  },
  methods: {
    handleInviteBorrower() {
      this.isAdd = true;
      this.$bvModal.show("add-borrower");
    },
    handleEditUser(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-borrower");
    },
    handleCloseModal() {
      this.$root.$emit("update_list");
      this.$bvModal.hide("add-borrower");
    },
    handleSelectborrower(borrower) {
      this.$emit("open_drawer", { item: { ...borrower } });
      this.borrower = borrower.item;
    },
    handleSetStatus(status) {
      this.current_page = 1;
      this.status = status;
      this.$emit("update_status", status);
    },
    handleResendInvite({ item: { id } }) {
      this.resendId = id;
      this.resend_invite(id).then(() => {
        this.resendId = null;
        this.show_toast({
          message: "Convite reenviado com sucesso",
          type: "success",
        });
        this.getList();
      });
    },
  },

  computed: {
    ...mapGetters(["is_mobile"]),
  },
};
</script>
