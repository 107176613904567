<template>
  <div>
    <div>
      <b-tabs pills small nav-class="nav-users">
        <b-tab
          @click="handleSelectMenu(menu)"
          :variant="selectedMenu === menu ? 'primary' : 'light'"
          v-for="(menu, index) in menus"
          :key="index"
          :title="menu.name"
        >
        </b-tab>
      </b-tabs>
      <list-registred
        v-if="selectedMenu.id === 'register'"
        :params="{ progress }"
        @open_drawer="handleSelectBorrower"
        action="borrower"
        button_name="Convidar"
        enable_event_edit
        base_view="/borrower"
      />
      <!-- 
        Dictionary params:
         status = status invite
         uy = user type
         type= type invite
         -->
      <list-invite
        v-if="selectedMenu.id === 'invites'"
        button_name="Convidar"
        @add_row="handleAddNewClient"
        :params="{ type: 'i', status, ut: 'b' }"
        action="notifications"
        base_view="/notifications"
        @resent_invite="handleResendInvite"
        @update_status="handleSetStatus"
        :resendId.sync="resendId"
      />
    </div>

    <b-modal
      id="add-borrower"
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      :title="`${selectedId ? 'Editar convite' : 'Convidar tomador'} `"
    >
      <Add :id="selectedId" @submited="handleCloseModal" />
    </b-modal>
  </div>
</template>

<script>
import Add from "./add";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import ListRegistred from "./list.registred.vue";
import ListInvite from "./list.invite.vue";

export default {
  components: {
    Add,
    ListRegistred,
    ListInvite,
  },
  data() {
    return {
      selected: {},
      borrower: [],
      selectedId: null,
      resendId: null,
      status: 1,
      percentage: 100,
      progress: 100,
      selectedMenu: {
        id: null,
      },
      menus: [
        {
          name: "Cadastrados",
          id: "register",
        },
        { name: "Listagem de convites", id: "invites" },
      ],
    };
  },
  methods: {
    ...mapActions(["show_toast", "delete_borrower", "resend_invite"]),
    handleAddNewClient(id) {
      this.selectedId = id || null;
      this.$bvModal.show("add-borrower");
    },
    handleCloseModal() {
      this.$root.$emit("update_list");
      this.$bvModal.hide("add-borrower");
    },
    handleSetStatus(status) {
      this.status = status;
    },
    handleChangePercentage: _.debounce(function(value) {
      this.progress = value;
    }, 500),

    handleSelectBorrower(borrower) {
      this.borrower = borrower.item;
    },

    handleResendInvite({ item: { id } }) {
      this.resendId = id;
      this.resend_invite(id).then(() => {
        this.resendId = null;
        this.show_toast({
          message: "Convite reenviado com sucesso",
          type: "success",
        });
        this.$refs.borrower_invite.getList();
      });
    },

    handleSelectMenu(user) {
      this.selectedMenu = user;
    },
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile"]),
  },
  mounted() {
    this.selectedMenu = this.menus[0];
  },
};
</script>

<style lang="scss">
.min-200 {
  min-width: 100px;
}
</style>
